import Image from 'next/image'
import clsx from 'clsx'
import {motion} from 'framer-motion'
import Link from 'next/link'

import {allInOneSolutionData} from 'v1/content/home-page'

import SmallLogoDark from 'v1/assets/icons/small-logo-dark.svg'
import ArrowRight from 'v1/assets/icons/arrow-right.svg'
import ChevronRight from 'v1/assets/icons/chevron-right.svg'
import user1 from 'v1/assets/icons/user-chip-icons/user-1.svg'
import user2 from 'v1/assets/icons/user-chip-icons/user-2.svg'
import user3 from 'v1/assets/icons/user-chip-icons/user-3.svg'
import classes from './styles.module.css'

export default function AllInOneSolution() {
  return (
    <section className={classes.allInOneContainer}>
      <h2 className={clsx('h2-v1 text-center', classes.heading)}>
        {allInOneSolutionData?.title1} {allInOneSolutionData?.title2}
      </h2>
      <div className={classes.multiCardContainer}>
        {allInOneSolutionData?.multiProductsCardList?.map((productDetails, idx) => (
          <div className={classes.multiCard} key={productDetails?.key}>
            <MultiProductCard details={productDetails} />
            {/* {lines[idx] && (
              <div
                className={clsx(
                  idx === 0 ? classes.svgLine0 : idx === 1 ? classes.svgLine1 : classes.svgLine2,
                )}
              >
                {lines[idx]}
              </div>
            )} */}
          </div>
        ))}
      </div>
      <SingleProductCard details={allInOneSolutionData.singleProductCardData} />
    </section>
  )
}

function MultiProductCard({details}: {details: any}) {
  const isZenIt = details?.key === 'zenit'
  const listArrowVariants = {
    hidden: {x: 0},
    hover: {x: 8},
  }

  const getList = (list: any) => (
    <li
      className={clsx(
        classes.productList,
        classes[`${details?.key}Border`],
        isZenIt && classes.productListHover,
      )}
    >
      <div className={classes.productListText}>
        <span className={classes.listCompanyIcon}>
          <Image src={SmallLogoDark} width={16} alt="company-logo" />
        </span>
        <span className="h8-v1">{list?.text}</span>
      </div>
      {isZenIt ? (
        <motion.div
          className={classes.listArrow}
          variants={listArrowVariants}
          initial="hidden"
          whileHover="hover"
          transition={{type: 'tween', duration: 0.3}}
        >
          <Image src={ArrowRight} width={16} alt="arrow" />
        </motion.div>
      ) : null}{' '}
    </li>
  )

  return (
    <div className={clsx(classes.multiProductCard, classes[`${details?.key}Border`])}>
      <div
        className={clsx(
          classes.multiCardHeader,
          classes[`${details?.key}Border`],
          classes[`${details?.key}BgColor`],
        )}
      >
        <Image src={details?.icon} width={40} alt="company-logo" />
        <div className={classes.headerDetails}>
          <h4 className="h4-v1">{details?.title}</h4>
          <p className="paragraph3-v1">{details?.subTitle}</p>
        </div>
      </div>
      <ul>
        {details?.list?.map((list: any, index: number) =>
          list?.link ? (
            <Link href={list?.link} key={index} style={{cursor: list?.link ? 'pointer' : 'auto'}}>
              {getList(list)}
            </Link>
          ) : (
            getList(list)
          ),
        )}
      </ul>
      <div className={classes.footerDetails}>
        <h5 className="h7-v1">{details?.footerData?.title}</h5>
        {details?.footerData?.content ? (
          <p className={classes.footerContent}>{details?.footerData?.content}</p>
        ) : null}
        {isZenIt ? (
          <Link href={details?.footerData?.knowMoreLink} className={classes.knowMoreBtn}>
            <span>Know more</span>
            <Image src={ChevronRight} width={12} alt="arrow-icon" />
          </Link>
        ) : null}
      </div>
    </div>
  )
}

function SingleProductCard({details}: {details: any}) {
  return (
    <div className={classes.singleProductCard}>
      <div className={clsx(classes.multiCardHeader, classes.singleCardHeader)}>
        <Image src={details?.icon} width={40} alt="company-logo" />
        <div className={classes.headerDetails}>
          <h4 className="h4-v1">{details?.title}</h4>
          <p className="paragraph3-v1">{details?.subTitle}</p>
        </div>
      </div>
      <div className={classes.singleCardListContainer}>
        {details?.list?.map((list: any, index: number) => (
          <div key={index} className={classes.singleCardList}>
            <span className={classes.listCompanyIcon}>
              <Image src={SmallLogoDark} width={16} alt="company-logo" />
            </span>
            <span className="h8-v1">{list?.text}</span>
          </div>
        ))}
      </div>
      <div className={classes.multiUserChipContainer}>
        <button className={classes.userChipInnerContainer}>
          <div className={classes.multiUserChipCard}>
            <Image src={user1} width={20} alt="user-icon" />
            <Image className={classes.UserChip} src={user2} width={20} alt="user-icon" />
            <Image className={classes.UserChip} src={user3} width={20} alt="user-icon" />
          </div>
          <span className="h8-v1">{details?.footerData?.title}</span>
        </button>
      </div>
    </div>
  )
}

const lines = [
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="52"
      viewBox="0 0 365 52"
      fill="none"
    >
      <path
        d="M.333 3a2.667 2.667 0 1 0 5.334 0A2.667 2.667 0 0 0 .333 3m362.313 48.354a.5.5 0 0 0 .708 0l3.182-3.182a.5.5 0 0 0-.708-.707L363 50.293l-2.828-2.828a.501.501 0 0 0-.708.707zM2.5 3v4h1V3zM23 27.5h320v-1H23zM362.5 47v4h1v-4zM343 27.5c10.77 0 19.5 8.73 19.5 19.5h1c0-11.322-9.178-20.5-20.5-20.5zM2.5 7c0 11.322 9.178 20.5 20.5 20.5v-1C12.23 26.5 3.5 17.77 3.5 7z"
        fill="#3157DC"
      />
    </svg>
  </>,
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="52" viewBox="0 0 8 52" fill="none">
      <path
        d="M1.333 3a2.667 2.667 0 1 0 5.334 0 2.667 2.667 0 0 0-5.334 0m2.313 48.354a.5.5 0 0 0 .708 0l3.182-3.182a.5.5 0 1 0-.708-.707L4 50.293l-2.828-2.828a.5.5 0 1 0-.708.707zM3.5 3v48h1V3z"
        fill="#3157DC"
      />
    </svg>
  </>,
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="52"
      viewBox="0 0 365 52"
      fill="none"
    >
      <path
        d="M361.333 3a2.667 2.667 0 1 0 5.334 0 2.667 2.667 0 0 0-5.334 0M3.646 51.354a.5.5 0 0 0 .708 0l3.182-3.182a.5.5 0 1 0-.708-.707L4 50.293l-2.828-2.828a.5.5 0 1 0-.708.707zM363.5 3v4h1V3zM344 26.5H24v1h320zM3.5 47v4h1v-4zM24 26.5C12.678 26.5 3.5 35.678 3.5 47h1c0-10.77 8.73-19.5 19.5-19.5zM363.5 7c0 10.77-8.73 19.5-19.5 19.5v1c11.322 0 20.5-9.178 20.5-20.5z"
        fill="#3157DC"
      />
    </svg>
  </>,
]
